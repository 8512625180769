module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"enableCustomId":true,"icon":false}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Solarized Light","extensions":["/codebuild/output/src811543113/src/bookish-carnival/extensions/shanoor.vscode-nginx-0.6.0.vsix","/codebuild/output/src811543113/src/bookish-carnival/extensions/silvenon.mdx-0.1.0.vsix","/codebuild/output/src811543113/src/bookish-carnival/extensions/hogashi.crontab-syntax-highlight-0.0.1.vsix"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":700}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src811543113/src/bookish-carnival"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Altmeta","short_name":"Altmeta","start_url":"/","background_color":"#FFFFFF","theme_color":"#ADD7CC","display":"minimal-ui","icon":"src/images/altmeta_logo_v1.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"49ecc0403e772dcafe725386014c4286"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
